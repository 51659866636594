.logo {
  max-height: 40px;
}

section {
  position: relative;
  padding: 6rem 0;

  &.intro {
    padding: 5rem 0 8rem;
  }

  &.padding-sm {
    padding: 3rem 0;
  }

  &.pb0 {
    padding-bottom: 0;
  }

  @media (max-width: 991px) {
    padding: 3rem 0;
  }
}

.hero {
  min-height: 450px;
  display: flex;
  align-items: center;
  &.hero-small {
    min-height: 300px;
  }
}

.hero-session {
  background-color: $secondary;
}

.section-title {
  margin-bottom: 2rem;
}

.overlay {
  background-color: rgba(28, 27, 26, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  &.strong {
    background-color: rgba(28, 27, 26, 0.75);
  }
}

.buttons {
  .button {
    margin: 0 8px 0 0;
  }
  .note {
    margin-top: 5px;
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    .button {
      margin: 0 0 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.bg-hero-intro {
  background: no-repeat 50% 20%;
  background-size: cover;
  background-image: url(../img/bg-hero-intro.jpg);
}

.bg-hero-session {
  padding: 10rem 0;
  background: no-repeat 50% 20%;
  background-size: cover;
  background-image: url(../img/bg-session.jpg);
}

.bg-collage {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/collage3.jpg);
}

.bg-cta-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-about.jpg);
}

.bg-home-frustrated {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-home-frustrated.jpg);
}

.bg-promise-home {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-promise-home.jpg);
}

.bg-cbf {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cbf.jpg);
}

.bg-parents {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-parents.jpg);
}

.bg-campus {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-campus.jpg);
}

.bg-elementary-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-elementary-hero-2.png);
}

.bg-middle-school-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-nest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-nest-hero.jpg);
}

.bg-hero-home {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-hero-home.jpg);
}

.bg-survivorcamp {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/summer-camps/survivor/bg-survivorcamp.jpg);
}

.bg-sciencecamp {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/summer-camps/science/bg-hero.jpg);
}

.bg-middle {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-cta-community-middle {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-community-middle.jpg);
}

.bg-apply-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-apply-hero.jpg);
}

.bg-different {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-different.jpg);
}

.bg-cta-community-elementary {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cta-community-elementary.jpg);
}

.testimonial {
  font-size: 1.5rem;
  line-height: 1.7;

  .content {
    font-weight: 200;
  }

  .attribution {
    color: $grey;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.5rem;
  }

  &.white-letters {
    color: white;
    .attribution {
      color: white;
    }
  }

  @media (max-width: 991px) {
    font-size: 1.25rem;
  }
}

.play {
  max-height: 80px;
}

.signature {
  max-height: 60px;
  max-width: 180px;
}

.editorial {
  text-align: left;

  p {
    font-weight: 300;
    font-size: 16px;
    color: $black;
    line-height: 1.82;
  }
}

.box {
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  padding: 30px;
}

.announcement {
  position: relative;
  background: $green;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  text-decoration: none;
  width: 100%;
  display: block;
  transition: 200ms all;
  z-index: 200;

  a {
    color: $blue;
    text-decoration: underline;
  }

  &.big {
    background: $bg-grey;
    font-size: 1.5rem;
    padding: 1rem;

    &:hover {
      text-decoration: none;
      background: darken($bg-grey, 5%);
    }
  }
}


.faqs-box {
  text-align: left;
  padding: 0 30px;
  margin: 3rem 0;

  .question-wrapper {
    padding: 30px 0;
    border-bottom: 1px solid rgba(black, 0.075);

    &.active .question::after {
      background-image: url(../img/icon-chevron-up.png);
    }
  }

  .question {
    color: $secondary;
    font-weight: 300;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: auto;
      padding-left: 50px;
      display: block;
      position: relative;
      width: 100%;
      max-width: 21px;
      height: 11px;
      background-image: url(../img/icon-chevron-down.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .answer {
    display: none;
    font-size: 0.9rem;
    margin-top: 1rem;
    line-height: 1.72;
    p {
      color: $black;
      font-weight: 300;
    }
    p:last-child {
      margin: 0;
    }
  }
}

.team-member,
.icon {
  max-height: 80px;
}

.img-circle {
  border-radius: 50%;
}

footer {
  background-color: white;
  padding: 5rem 0;
  border-top: 1px solid rgba(black, 0.075);

  a {
    color: $grey;
    display: block;
    margin-bottom: 1rem;
    transition: 200ms all;

    &:hover,
    &:focus,
    &:active {
      color: lighten($secondary, 15%);
      text-decoration: none;
    }
  }

  .copyright {
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.social-icons {
  margin-bottom: 3rem;
  .social-icon {
    display: inline-block;
    margin: 0 3px;
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 18px;
    text-align: center;
    color: white;
    background: $grey;
    border-radius: 50%;
    transition: 200ms all;

    &:hover {
      background: lighten($blue, 10%);
    }
  }
}

.contact-card {
  display: flex;
  align-items: center;
  font-weight: 300;
  img {
    margin-right: 1rem;
  }

  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: $light-blue;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.18em;
  }

  p {
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.feature {
  text-align: left;
  h5 {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.72;
  }
}

.img-mega-wrapper {
  position: relative;
  margin-top: 40px;
  img {
    position: absolute;
    right: 0;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.img-featured {
  max-height: 450px;
}

.letter {
  text-align: left;
  background-color: $bg-grey;
  box-shadow: 5px 5px 20px 0px rgba(46, 61, 73, 0.2);

  .content {
    padding: 30px;
  }
}

.input-tall {
  padding: 0.8rem;
}

.input-sm {
  width: 3rem;
}

  .form-section {
    margin: 2rem 0;
  }


.height100 {
  height: 100%;
}

legend{
  font-size: 1rem;
  font-weight: bold;
}

.img-wrapper {
  height: 280px;
  width: auto;
  max-width: 100%;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  &.vertical {
    height: 400px;
  }
}


.scroll-down {
  opacity: 1;
  -webkit-transition: all .5s ease-in 3s;
  transition: all .5s ease-in 3s;
}

.scroll-down {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -16px;
  display: block;
  width: 32px;
  height: 32px;
  border: 2px solid #FFF;
  background-size: 14px auto;
  border-radius: 50%;
  z-index: 2;
  -webkit-animation: bounce 2s infinite 2s;
  animation: bounce 2s infinite 2s;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  transform: scale(1)
}

.scroll-down:before {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid white;
    border-width: 0px 0 2px 2px;
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

html {
  scroll-behavior: smooth;
}

.navigation-box {
  position: relative;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 200ms all;
  background-color: $primary;
  .box-header {
    padding: 2rem 30px 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .box-body {
    padding: 1rem 30px;
    p { font-size: 1rem; }
    p:last-child { margin-bottom: 0; }
  }

  .box-footer {
    margin-top: auto;
    padding: 1rem 30px 2rem;
  }
}

.calendar {
  width: 64px;
  height: 52px;
  background-image: url(../img/calendar.svg);
  background-size: contain;
  display: block;
  margin: 0 auto;
}
