html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: "Montserrat";
  line-height: 1.43;
  margin: 0;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  padding-top: 107px;

  &.pb-sticky-bar-active { padding-top: 110px; }

  @media(max-width: 991px) {
    padding-top: 117px;
    &.pb-sticky-bar-active { padding-top: 79px; }
  }
}

h1 {
  font-size: 4rem;
  @media (max-width: 991px) { font-size: 2rem; }
}

h2 {
  font-size: 2.25rem;
  @media (max-width: 991px) { font-size: 1.85rem; }
}

h3 {
  font-size: 1.5rem;
}

a {
  color: $secondary;
}

.editorial {
  line-height: 1.8;
}

.uppercase {
  text-transform: uppercase;
}

.hide, .hidden {
  display: none;
}

.center, .centered {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold, strong {
  font-weight: bold;
}

.light {
  font-weight: 300;
}

.no-effects {
  &:hover, &:focus, &:active { outline: none; }
}

.white-letters {
  color: white;
  h1, h2, h3, h4, h5, h6, p, a, ul, li {
    color: white;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.bring-to-front {
  z-index: 100;
}

.hidden-mobile {
  @media(max-width: 991px) {
    display: none;
  }
}

.centered-mobile {
  @media(max-width: 991px) {
    text-align: center;
  }
}

.nowrap {
  white-space: nowrap;
}
.video-link {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: $bg-grey;
  text-align: left;
  box-shadow: 3px 3px 8px 0px rgba(46, 61, 73, 0.1);

  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .title {
    color: $black;
  }

  .video-link-footer {
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
    color: white;
    border-radius: 50%;
    background-color: $primary;
    font-size: 1rem;
    margin-right: 1.5rem;
  }

  .author {
    display: block;
    color: $grey;
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }

  &:hover, &:focus, &:active {
    text-decoration: none;
    transform: translateY(-3px);

    .icon {
      background-color: $secondary;
    }
  }
}
